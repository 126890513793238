import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from "../../contexts/LanguageContext";
import importImage from "../../utils/images/importImage";


const Footer = () => {
    const footerLogo = importImage("logo-dark.png");
    const { t } = useLanguage();

  return (
  <>
   <footer className="bg-dark">  
    <div className="container-fluid bg-dark text-light wow fadeInUp py-5" data-wow-delay="0.1s">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                <h2 className="footer-heading mb-4"><a href="#" className="logo text-white">
                    {/*TOKOYARN*/}
                    </a>
                 <img
                  src={footerLogo}
                  className="main-logo"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "cover", maxWidth:"100px",maxHeight:"100px"  }}
                  alt="logo"
                /> 
                    </h2>
                <div className="menu mb-4">
                    <a href="/" className="mr-3 active">{t("header.home")}</a>
                    <a href="/about" className="mr-3">{t("header.about")}</a>
                    <a href="/news" className="mr-3">{t("header.news")}</a>
                    {/* <a href="#services" className="mr-3">Services</a>
                    <a href="#testimonials" className="mr-3">Testimonials</a>
                    <a href="#faq" className="">Faqs</a> */}
                </div>
                <div className="ftco-footer-social p-0 d-flex justify-content-center align-items-center">
                    <a className="btn btn-lg btn-primary btn-lg-square rounded mx-2" href=""><i class="bi bi-twitter-x"></i></a>
                    <a className="btn btn-lg btn-primary btn-lg-square rounded mx-2" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                    <a className="btn btn-lg btn-primary btn-lg-square rounded mx-2" href=""><i className="fab fa-instagram fw-normal"></i></a>
                    <a className="btn btn-lg btn-primary btn-lg-square rounded mx-2" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                </div>
                </div>
                </div>
        </div>
    </div>
    <div className="container-fluid text-white">
        <div className="container text-center">
            <div className="row justify-content-end">
                <div className="col-lg-12 col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                        <p className="mb-5">&copy; <a className="text-white" href="#">Copyright © 2024 Tokoyarn - All Rights Reserved.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </footer>
  </>
  );
};

export default Footer;
