import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { showToast } from '../../utils/toast';
import { postApi } from '../../utils/api/api';
import DeleteImages from '../Common/DeleteImages';

const AddInventoryForm = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    material: '',
    qty: '',
    qty_type: '',
    price: '',
    description: '',
    p1: '',
    p2: '',
    p3: '',
    p4: '',
    p5: '',
    p6: '',
    p7: '',
    p8: '',
    p9: '',
    p10: '',
    dying : '',
    images: [],
    certificate: null,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const materialsOptions = ["Cotton 100%","PES 100%",'TC PES 60% Cotton 40%','TC PES 52% Cotton 48%','CVC Cotton 60% PES 40%',
                            'CVC Cotton 52% PES 48%','TR PES 60% Rayon 40%','TR PES 65% Rayon 35%','Rayon 100% Raw','Rayon 100% White',
                            'LWA Eco','Modal','Bamboo','Blends Specify'];
  const spinningProcessOptions = ['Carded','Combed','Compact','Non compact'];
  const spinningTypeOptions = ["Ring","MVS","Rigs"];
  const pesTypeOptions = ['Semi-dull','Bright','Super Bright','High Tenacity'];
  const qualityGradeOptions = ["A","A non","B","C"];
  const endUseOptions = ["Knitting","Weaving"];
  const dyingOptions = ["Suitable",'Non-Suitable','Specify'];
  const specialYarnsOptions = ['Filament DTY','Filament FDY','Filament SDY','Nylon','Misty/Melange M61','Misty/Melange M71','Misty/Melange M81',
                              'Misty/Melange 100% Black','Sewing Threads','Specify'];
  const quantityTypeOptions = ['Kg', 'Bundle'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, [field]: file }));
    setErrors((prev) => ({ ...prev, [field]: '' })); // Clear error for this field
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
    if (files.length > 0) {
      setErrors((prev) => ({ ...prev, images: '' })); // Clear error for images
    }
  };

  const handleImageDelete = (index) => {
    setFormData((prev) => {
      const updatedImages = prev.images.filter((_, i) => i !== index);
      return { ...prev, images: updatedImages };
    });
  };

  const handleCertificateDelete = () => {
    setFormData((prev) => ({ ...prev, certificate: null }));
  };

  const validateForm = () => {
    const { name, material, qty, qty_type, price, description, images, certificate } = formData;
    const newErrors = {};

    if (!name.trim()) newErrors.name = t('errors.nameRequired', 'Product Name is required.');
    if (!material.trim()) newErrors.material = t('errors.materialRequired', 'Material is required.');
    if (!qty || isNaN(qty) || qty <= 0) newErrors.qty = t('errors.qtyInvalid', 'Enter a valid quantity.');
    if (!qty_type.trim()) newErrors.qty_type = t('errors.qtyTypeRequired', 'Quantity Type is required.');
    if (!price || isNaN(price) || price <= 0) newErrors.price = t('errors.priceInvalid', 'Enter a valid price.');
    if (!description.trim()) newErrors.description = t('errors.descriptionRequired', 'Description is required.');
    if (images.length === 0) newErrors.images = t('errors.imageRequired', 'Please upload at least one image.');
    if (!certificate) newErrors.certificate = t('errors.certificateRequired', 'Please upload a certificate.');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const payload = { ...formData };

      const formDataPayload = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key === 'images') {
          payload.images.forEach((image) => formDataPayload.append('images[]', image));

        } else if (key === 'certificate') {
          formDataPayload.append('certificate', payload.certificate);
        } else {
          formDataPayload.append(key, payload[key]);
        }
      });
      console.log("formDataPayload::", formDataPayload);

      await postApi('/seller/create-product', formDataPayload);
      showToast('success', t('success.inventoryAdded', 'Inventory added successfully!'));
      navigate('/seller-dashboard');
    } catch (err) {
      showToast('error', err.message || t('errors.submitFailed', 'Failed to submit the form.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper py-5">
      <div className="auth-container container">
        <div className="card form-card p-4">
          <h2 className="text-primary mb-4">{t('addInventory.heading', 'Add Inventory')}</h2>
          <form onSubmit={handleSubmit}>
        <div className='row'>
            <div className='col-lg-6'>
          <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.name', 'Product Name')}<sup>*</sup></label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.name && <small className="text-danger">{errors.name}</small>}
            </div>
            </div>

            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.yarn_count', 'Yarn count')}<sup>*</sup></label>
              <input
                type="number"
                name="yarn_count"
                value={formData.yarn_count}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.yarn_count && <small className="text-danger">{errors.yarn_count}</small>}
            </div>
            </div>

            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.spinning_process', 'Spinning process')}<sup>*</sup></label>
              <select
                name="spinning_process"
                value={formData.spinning_process}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.spinning_process', 'Choose Spinning process...')}</option>
                {spinningProcessOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.spinning_process && <small className="text-danger">{errors.spinning_process}</small>}
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.spinning_type', 'Spinning type')}<sup>*</sup></label>
              <select
                name="spinning_type"
                value={formData.spinning_type}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.spinning_type', 'Choose Spinning type...')}</option>
                {spinningTypeOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.spinning_type && <small className="text-danger">{errors.spinning_type}</small>}
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.material', 'Material')}<sup>*</sup></label>
              <select
                name="material"
                value={formData.material}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.material', 'Choose a material...')}</option>
                {materialsOptions.map((material, index) => (
                  <option key={index} value={material}>
                    {material}
                  </option>
                ))}
              </select>
              {errors.material && <small className="text-danger">{errors.material}</small>}
            </div>
            </div>
            {formData.material === 'Blends Specify' && (
              <div className='col-lg-6'>
                <div className="form-group">
                <label className="form-label text-dark">Material Blends Specify</label>
                  <input
                    type="text"
                    name="material_text"
                    value={formData.material_text} // Ensure the correct key is used
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                </div>
              </div>
            )}
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.pes_type', 'PES type')}<sup>*</sup></label>
              <select
                name="pes_type"
                value={formData.pes_type}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.pes_type', 'Choose PES type...')}</option>
                {pesTypeOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.pes_type && <small className="text-danger">{errors.pes_type}</small>}
            </div>
            </div>

            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.quality_grade', 'Quality Grade')}<sup>*</sup></label>
              <select
                name="quality_grade"
                value={formData.quality_grade}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.quality_grade', 'Choose Quality Grade...')}</option>
                {qualityGradeOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.quality_grade && <small className="text-danger">{errors.quality_grade}</small>}
            </div>
            </div>

            <div className='col-lg-12'>
            <label className="form-label">Quality Parameters</label>
            </div>
            
            <div className='col-lg-6'>
          <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.ipi', 'IPI')}<sup>*</sup></label>
              <input
                type="text"
                name="ipi"
                value={formData.ipi}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.ipi && <small className="text-danger">{errors.ipi}</small>}
            </div>
            </div>
            <div className='col-lg-6'>
          <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.rkm', 'RKM')}<sup>*</sup></label>
              <input
                type="text"
                name="rkm"
                value={formData.rkm}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.rkm && <small className="text-danger">{errors.rkm}</small>}
            </div>
            </div>


            
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.dying', 'Printing/Dying')}<sup>*</sup></label>
              <select
                name="dying"
                value={formData.dying}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.dying', 'Choose Printing/Dying...')}</option>
                {dyingOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.dying && <small className="text-danger">{errors.dying}</small>}
            </div>
            </div>
            {formData.dying === 'Specify' && (
              <div className='col-lg-6'>
                <div className="form-group">
                <label className="form-label text-dark">Printing/Dying Specify</label>
                  <input
                    type="text"
                    name="dying_text"
                    value={formData.dying_text} // Ensure the correct key is used
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                </div>
              </div>
            )}
            
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.special_yarn', 'Special Yarn')}<sup>*</sup></label>
              <select
                name="special_yarn"
                value={formData.special_yarn}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.special_yarn', 'Choose Special Yarn...')}</option>
                {specialYarnsOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.special_yarn && <small className="text-danger">{errors.special_yarn}</small>}
            </div>
            </div>
              {formData.special_yarn === 'Specify' && (
              <div className='col-lg-6'>
                <div className="form-group">
                <label className="form-label text-dark">Special Yarn Specify</label>
                  <input
                    type="text"
                    name="special_yarn_text"
                    value={formData.special_yarn_text} // Ensure the correct key is used
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                </div>
              </div>
            )}
              <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.end_use', 'End Use')}<sup>*</sup></label>
              <select
                name="end_use"
                value={formData.end_use}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.end_use', 'Choose End Use...')}</option>
                {endUseOptions.map((process, index) => (
                  <option key={index} value={process}>
                    {process}
                  </option>
                ))}
              </select>
              {errors.end_use && <small className="text-danger">{errors.end_use}</small>}
            </div>
            </div>

            
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.qty', 'qty')}<sup>*</sup></label>
              <input  
                type="number"
                name="qty"
                value={formData.qty}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.qty && <small className="text-danger">{errors.qty}</small>}
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.qtyType', 'Quantity Type')}<sup>*</sup></label>
              <select
                name="qty_type"
                value={formData.qty_type}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              >
                <option value="">{t('addInventory.placeholders.qty_type', 'Choose a quantity type...')}</option>
                {quantityTypeOptions.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.qty_type && <small className="text-danger">{errors.qty_type}</small>}
            </div>
            </div>
            <div className='col-lg-12'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.price', 'Price')}<sup>*</sup></label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="form-control border-0 bg-light px-4"
              />
              {errors.price && <small className="text-danger">{errors.price}</small>}
            </div>
            </div>
            <div className='col-lg-12'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.description', 'Description')}<sup>*</sup></label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                maxlength="500"
                className="form-control border-0 bg-light px-4"
              ></textarea>
              {errors.description && <small className="text-danger">{errors.description}</small>}
            </div>
            </div>
{/*            <div className='col-lg-12'>
            <label className="form-label">Parameter</label>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p1</label>
                  <input
                    type="text"
                    name="p1"
                    value={formData.p1}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p2</label>
                  <input
                    type="text"
                    name="p2"
                    value={formData.p2}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p3</label>
                  <input
                    type="text"
                    name="p3"
                    value={formData.p3}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p4</label>
                  <input
                    type="text"
                    name="p4"
                    value={formData.p4}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p5</label>
                  <input
                    type="text"
                    name="p5"
                    value={formData.p5}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p6</label>
                  <input
                    type="text"
                    name="p6"
                    value={formData.p6}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p7</label>
                  <input
                    type="text"
                    name="p7"
                    value={formData.p7}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p8</label>
                  <input
                    type="text"
                    name="p8"
                    value={formData.p8}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p9</label>
                  <input
                    type="text"
                    name="p9"
                    value={formData.p9}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p10</label>
                  <input
                    type="text"
                    name="p10"
                    value={formData.p10}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>*/}
            <div className='col-lg-12'>
            <div className="form-group">
              <label className="form-label text-dark">{t('addInventory.labels.images', 'Product Images')}<sup>*</sup></label>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
                className="form-control border-0 bg-light px-4"
              />
              <DeleteImages images={formData.images} onDelete={handleImageDelete} />
              {errors.images && <small className="text-danger">{errors.images}</small>}
              {/* {formData.images.length === 0 && <small className="text-muted">No files selected</small>} */}
                  {errors.images && <small className="text-danger">{errors.images}</small>}
              {/* <div className="d-flex mt-2">
                {formData.images.map((image, index) => (
                  <div key={index} className="mr-2">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="preview"
                      className="img-thumbnail"
                      style={{ width: 100, height: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mt-1"
                      onClick={() => handleImageDelete(index)}
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                ))}
              </div> */}
              </div>
            </div>
            <div className='col-lg-12'>
            <div className="form-group certificate-field">
              <label className="form-label text-dark">{t('addInventory.labels.certificate', 'Upload Certificate')}<sup>*</sup></label>
              <input
                type="file"
                accept="application/pdf,image/*"
                onChange={(e) => handleFileChange(e, 'certificate')}
                className="form-control border-0 bg-light px-4"
              />
              {formData.certificate && (
                <DeleteImages
                  images={formData.certificate}
                  onDelete={handleCertificateDelete}
                  isSingle
                />
              )}
              {errors.certificate && <small className="text-danger">{errors.certificate}</small>}
            </div>
            </div>
            <div className='col-lg-12'>
            <button
              type="submit"
              className="btn btn-secondary py-3 px-5 mt-3"
              disabled={loading}
            >
              {loading ? t('addInventory.submitting', 'Submitting...') : t('addInventory.buttons.submit', 'Submit')}
            </button>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddInventoryForm;